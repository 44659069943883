
	import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { Actions, Getters } from '@/modules/entities/store/modules/suppliers/types/StoreTypes';
	import { SupplierType } from '@/modules/entities/types/entities';

	const suppliers = namespace('entities/suppliers');

	@Component
	export default class SelectSupplierTypeComponent extends Vue {
		@Prop({ type: Boolean, default: false }) value!: boolean;

		@suppliers.Action(Actions.FETCH_SUPPLIER_TYPES) fetchSupplierTypes!: () => void;

		@suppliers.Getter(Getters.GET_SUPPLIER_TYPES) supplierTypes!: SupplierType[];

		selectedSupplierTypeId = -1;

		created(): void {
			this.fetchSupplierTypes();
		}

		@Emit('onChooseThirdPartySupplier')
		onEmitChooseThirdPartySupplier() {
			return this.selectedSupplierTypeId;
		}

		@Emit('onSupplierTypeSelect')
		onEmitSupplierTypeSelect() {
			return this.selectedSupplierTypeId;
		}

		@Watch('supplierTypes', { immediate: true })
		onSupplierTypesChange(newVal: SupplierType[]) {
			if (newVal.length) {
				this.selectedSupplierTypeId = newVal[0].id;
			}
		}
	}
